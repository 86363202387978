import('./bootstrap').catch((err) => console.error(err));

export {};

declare global {
  interface Window {
    grecaptcha: any;
    clarity: any;
  }
}
